export default {
  name: 'timer',
  props: [ 'time' ],
  data() {
    return {
      sliderWidth: 0,
      timeLeft: null,
      timeInterval: null
    }
  },
  created() {
    this.timeLeft = this.time
    this.startDecrementingTime()
  },
  destroyed() {
    this.stopDecrementingTime
  },
  computed: {
    minLeft() {
      const minutes = Math.floor(this.timeLeft / 60000)
      return minutes < 10 ? `0${minutes}` : minutes
    } ,
    secLeft() {
      const seconds = ((this.timeLeft % 60000) / 1000).toFixed(0)
      return seconds < 10 ? `0${seconds}` : seconds
    }
  },
  methods: {
    decrementTime() {
      this.timeLeft -= 1000
      this.incrementSlider()
      if (this.timeLeft <= 0) {
        this.$emit('timer-finished')
        this.stopDecrementingTime()
      }
    },
    incrementSlider() {
      const loadingBar = document.querySelector('.loading_bar')
      if (!loadingBar) return
      const bounds = loadingBar.getBoundingClientRect()
      this.sliderWidth = bounds.width - (bounds.width * (this.timeLeft / this.time)) + 'px'
    },
    startDecrementingTime() {
      this.timeInterval = setInterval(this.decrementTime, 1000)
    },
    stopDecrementingTime() {
      clearInterval(this.timeInterval)
    }
  }
}