import GuyImage from '@/components/guy-image'
import Timer from '@/components/timer'
import { mapActions } from 'vuex'

export default {
  name: 'task',
  components: {
    GuyImage,
    Timer
  },
  data() {
    return {
      correctOrderCount: 0,
      hasTimeDelay: false,
      heading: '',
      id: '',
      incorrectOrderCount: 0,
      itemsToOrder: [
        { name: 'Popcorn', image: require('@/assets/popcorn.png')},
        { name: 'Drink', image: require('@/assets/drink.png')},
        { name: 'Icecream', image: require('@/assets/icecream.jpg')},
        { name: 'Candy', image: require('@/assets/candy.png')}
      ],
      ladyImage: require('@/assets/lady.png'),
      order: [],
      showingTargetOrders: false,
      sizes: [ 'Small', 'Medium', 'Large' ],
      selectedItems: [],
      targetOrders: null,
      timeLeft: 10000, // 3 minutes by default
      timeDelay: 3000, // 3 seconds by default
      wrongAnswerCount: 0,
      wrongAnwserPrompt: false
    }
  },
  computed: {
    orderText() {
      if (!this.order) return ''
      return this.order.map(item => { return item.size + ' ' + item.name })
    }
  },
  mounted() {
    const { heading, hasTimeDelay, targetOrders, id } = this.$route.query
    this.heading = heading
    this.id = id
    if (hasTimeDelay) this.hasTimeDelay = (hasTimeDelay === 'true') ? true : false
    if (targetOrders) {
      this.showingTargetOrders = true
      this.targetOrders = targetOrders
    } else {
      this.newOrder()
    }
  },
  destroyed() {
  },
  methods: {
    ...mapActions(['storeTaskInfo']),
    beginTask() {
      this.showingTargetOrders = false
      this.newOrder()
    },
    buttonIsSelected(button) {
      return this.selectedItems.some(item => item.name === button.name)
    },
    clearWrongAnwserPrompt() {
      this.wrongAnwserPrompt = false
    },
    getThreeItems() {
      let items = []
      while (items.length < 3) {
        const randomItem = {...this.getRandomItem()}
        if (!items.some(({name}) => name === randomItem.name)) {
          items = [ ...items, { ...randomItem, size: this.getRandomSize() } ]
        }
      }
      return items
    },
    getRandomItem() {
      const randomIdx = Math.floor(Math.random() * this.itemsToOrder.length)
      return this.itemsToOrder[randomIdx]
    },
    getRandomSize() {
      const randomIdx = Math.floor(Math.random() * this.sizes.length)
      return this.sizes[randomIdx]
    },
    newOrder() {
      this.order = this.getThreeItems()
    },
    resetSelections() {
      this.selectedItems = []
    },
    selectItem(button) {
      if (this.buttonIsSelected(button)) this.selectedItems = this.selectedItems.filter(item => item.name !== button.name)
      else if (this.selectedItems.length === 3) return
      else this.selectedItems.push(button)
    },
    submitOrder() {
      const isCorrectOrder = this.selectedItems.filter(item => this.order.map(order => order.name).includes(item.name)).length === 3
      this.resetSelections()
      if (isCorrectOrder) {
        this.correctOrderCount += 1
        this.newOrder()
        this.wrongAnswerCount = 0
      } else {
        this.incorrectOrderCount += 1
        this.wrongAnswerCount += 1
        if (this.wrongAnswerCount === 5) {
          this.wrongAnswerCount = 0
          this.correctOrderCount = 0
        }
        this.wrongAnwserPrompt = true
      }
    },
    timerFinished() {
      this.storeTaskInfo({
        id: this.id,
        heading: this.heading,
        correctOrderCount: this.correctOrderCount,
        incorrectOrderCount: this.incorrectOrderCount,
        hasBonus: this.id !== 'p1' && this.id !== 'p2',
        targetOrders: this.targetOrders,
        hasTimeDelay: this.hasTimeDelay
      })
      this.$router.push(`/task-results?id=${this.id}&heading=${this.heading}&targetOrders=${this.targetOrders}&hasTimeDelay=${this.hasTimeDelay}`)
    }
  }
}